import { FolderIcon } from '@otto-finance/ui';
import { AssetsIcon, BiritshPoundCircleIcon, BookIcon, CashflowIcon, EventsIcon, GridIcon, IconProps, InvestmentAnalysisIcon, InvestmentIcon, InvestmentIdeasIcon, MapPinIcon, MessagesIcon, PlayCircleIcon, TargetIcon, TransactionIcon, UsersIcon } from 'common/otto-ui/icons';
import { FeatureFlagEnum } from 'configuration/data';
import { ROUTE_ENUM } from 'configuration/data/routers';
import {
  LogoAdmins,
  LogoCalendar,
  LogoUserList
} from 'shared/logos';

export interface SidebarItem {
  title: string;
  name: string;
  icon: React.FC<IconProps>;
  route?: ROUTE_ENUM;
  href: ROUTE_ENUM;
  acl?: string;
  /**
   * Is the link accessible
   */
  disabled?: boolean;
  /**
   * The feature tier associated with this page
   */
  tierSlug?: FeatureFlagEnum;
  /**
   * Shows a "New"
   */
  isNew?: boolean;
  /**
   * If this is a feature coming soon
   */
  isComingSoon?: boolean;
  /**
   * Description of the page
   */
  description?: string;
  isActive?: boolean;
  isExternal?: boolean;
}

export interface SidebarData extends SidebarItem {
  /**
   * If the page has any nested links
   */
  children?: Partial<SidebarItem>[];
}

export enum ACL {
  READ = 'read',
  CREATE = 'create',
}


// TODO: Remove "new" labels after 2 weeks
export const sidebarDataObject: SidebarData[] = [
  {
    title: 'Home',
    name: 'home',
    icon: GridIcon,
    href: ROUTE_ENUM.HOME,
    acl: ACL.READ,
    children: []
  },
  {
    title: 'Wealth',
    name: 'wealth',
    icon: BiritshPoundCircleIcon,
    route: ROUTE_ENUM.WEALTH,
    href: ROUTE_ENUM.WEALTH_INVESTMENTS,
    acl: ACL.READ,
    tierSlug: FeatureFlagEnum.WEALTH,
    children: [
      {
        title: 'Investment Portfolio',
        name: 'investments',
        href: ROUTE_ENUM.WEALTH_INVESTMENTS,
        acl: ACL.READ,
        icon: InvestmentIcon,
        isNew: true,
        description: "Track, analyse, and optimise your portfolio's performance."
      },
      {
        title: 'Investment Analysis',
        name: 'investment_analysis',
        href: ROUTE_ENUM.WEALTH_INVESTMENT_ANALYSIS,
        acl: ACL.READ,
        tierSlug: FeatureFlagEnum.WEALTH_ANALYSIS,
        icon: InvestmentAnalysisIcon,
        isNew: true,
        description: "Gain clarity on your exposure to key portfolio risk factors."
      },
      {
        title: 'Investment Ideas',
        name: 'investment_ideas',
        href: ROUTE_ENUM.WEALTH_INVESTMENT_IDEAS,
        acl: ACL.READ,
        tierSlug: FeatureFlagEnum.WEALTH_IDEAS,
        icon: InvestmentIdeasIcon,
        isNew: true,
        description: "Discover tailored investment opportunities."
      },
      {
        title: 'Cashflow',
        name: 'cashflow',
        href: ROUTE_ENUM.WEALTH_CASHFLOW,
        acl: ACL.READ,
        icon: CashflowIcon,
        description: "Explore spending, transactions, and  budgeting insights."
      },
      {
        title: 'Assets',
        name: 'assets',
        href: ROUTE_ENUM.WEALTH_ASSETS,
        acl: ACL.READ,
        icon: AssetsIcon,
        description: "Connect and manage your financial accounts."
      },
      {
        title: 'Transactions',
        name: 'transactions',
        href: ROUTE_ENUM.TRANSACTIONS,
        acl: ACL.READ,
        icon: TransactionIcon,
        description: "Review past transactions and account activity."
      }
    ],
  },
  {
    title: 'Actions',
    name: 'actions',
    icon: TargetIcon,
    href: ROUTE_ENUM.ACTIONS,
    acl: ACL.READ,
    tierSlug: FeatureFlagEnum.GOALS,
    children: []
  },
  {
    title: 'Rules',
    name: 'ottomate',
    icon: PlayCircleIcon,
    href: ROUTE_ENUM.OTTOMATE,
    acl: ACL.READ,
    tierSlug: FeatureFlagEnum.WEALTH,
    children: []
  },
  {
    title: 'Roadmap',
    name: 'roadmap',
    icon: MapPinIcon,
    href: ROUTE_ENUM.ROADMAP,
    acl: ACL.READ,
    tierSlug: FeatureFlagEnum.ROADMAP,
    children: []
  },
  {
    title: 'Users',
    name: 'users',
    icon: LogoUserList,
    href: ROUTE_ENUM.USERS,
    acl: ACL.READ,
    children: []
  },
  {
    title: 'Sessions',
    name: 'sessions',
    icon: LogoCalendar,
    href: ROUTE_ENUM.SESSION_LIST,
    acl: ACL.READ,
    tierSlug: FeatureFlagEnum.CALENDAR,
    children: []
  },
  {
    title: 'Documents',
    name: 'documents',
    icon: () => <FolderIcon />,
    href: ROUTE_ENUM.DOCUMENTS,
    acl: ACL.READ,
    // tierSlug: FeatureFlagEnum.DOCUMENTS,
    children: [],
  },
  {
    title: 'Learn',
    name: 'learn',
    icon: BookIcon,
    route: ROUTE_ENUM.LEARN,
    href: ROUTE_ENUM.EVENTS,
    acl: ACL.READ,
    children: [
      {
        title: 'Events',
        name: 'events',
        href: ROUTE_ENUM.EVENTS,
        acl: ACL.READ,
        icon: EventsIcon,
      },
      {
        title: 'Articles',
        name: 'articles',
        acl: ACL.READ,
        href: ROUTE_ENUM.LEARN,
        isNew: false,
        icon: InvestmentIdeasIcon
      },
    ],
  },
  {
    title: 'Coach',
    name: 'coach',
    icon: UsersIcon,
    route: ROUTE_ENUM.COACH,
    href: ROUTE_ENUM.COACH,
    acl: ACL.READ,
    children: [],
  },
  {
    title: 'Messages',
    name: 'messages',
    icon: MessagesIcon,
    href: ROUTE_ENUM.MESSAGES,
    acl: ACL.CREATE,
    tierSlug: FeatureFlagEnum.MESSAGING,
    children: []
  },
  {
    title: 'Coaches',
    name: 'coaches',
    icon: UsersIcon,
    href: ROUTE_ENUM.COACHES,
    acl: ACL.READ,
    children: []
  },
  {
    title: 'Admins',
    name: 'admins',
    icon: LogoAdmins,
    href: ROUTE_ENUM.ADMINS,
    acl: ACL.READ,
    children: []
  },
  {
    title: 'Availability',
    name: 'availability',
    icon: LogoCalendar,
    href: ROUTE_ENUM.AVAILABILITY,
    acl: ACL.READ,
    children: []
  }
];

export const AuthTypes = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  COACH: 'coach',
};

export const USER_STATUS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Invited', value: 'INVITED' },
];

export const routeTiers = [
  ...sidebarDataObject.filter(f => f.tierSlug).map(r => ({ href: r.href, tier: r.tierSlug })),
  ...sidebarDataObject.filter(f => f.tierSlug).map(r => r.children.flat().filter(f => f.tierSlug).map(cr => ({ href: cr.href, tier: cr.tierSlug }))).filter(f => f.length).flat()
];
