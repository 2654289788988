import { useMobile } from "common/roq-hooks/use-mobile";
import { ROUTE_ENUM } from "configuration/data/routers";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { memo } from "react";

const Navbar = dynamic(() => import('components/navbar').then((c) => c.Navbar), { ssr: false });
const NavbarMobile = dynamic(() => import('components/navbar/navbar-mobile').then((c) => c.NavbarMobile), { ssr: false });

const excludeRoutes = [
  ROUTE_ENUM.WELCOME,
  ROUTE_ENUM.LOGIN,
  ROUTE_ENUM.REGISTER,
  ROUTE_ENUM.FORGOT_PASS,
  ROUTE_ENUM.RESTORE_PASS,
  ROUTE_ENUM.ACCOUNT_ACTIVATE,
  ROUTE_ENUM.SUB_EXPIRED,
  ROUTE_ENUM.AUTH_REDIRECTION,
  ROUTE_ENUM.ONBOARDING_MESSAGE
];

export const AppNavigation = memo(() => {
  const isMobile = useMobile();
  const { route } = useRouter();
  if (excludeRoutes.includes(route as ROUTE_ENUM)) {
    return null;
  }
  return (
    isMobile ? (
      <NavbarMobile />
    ) : (
      <Navbar />
    )
  );
});
