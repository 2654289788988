import { FormControl, Slider, SliderProps } from "@otto-finance/ui";
import { useField } from 'formik';

export const FormSliderField = ({ label, name, ...props }: Omit<SliderProps, 'value'> & { name: string; label: string; }) => {
  const [field, _, helper] = useField({ name });
  return (
    <FormControl label={label}>
      <Slider
        {...props}
        value={[field.value]}
        onChange={({ value }) => helper.setValue(value[0])}
      />
    </FormControl>
  );
};
