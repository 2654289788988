export { 
  setPageFull, 
  setDashboardView, 
  setPrompts, 
  switchTheme, 
  uiSelector, 
  uiPromptsSelector, 
  setWealthTab, 
  updateThemeSettings,
  uiSettingsSelector,
  setPageTour,
  UiThemeEnum, 
  WealthTabsEnum
} from './ui.slice';
export type { UiInterface } from './ui.slice';
