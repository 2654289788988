import { createSlice } from "@reduxjs/toolkit";
import { fetchAuthUrlAction, fetchOwnerAction, redeemAccessTokenAction } from 'slices/calendar/actions';

export interface CalendarStateInterface {
  isLoading: boolean;
  error: any;
  redeemError: any;
  authUrl: string;
  externalUserId: string | null;
}
const initialState: CalendarStateInterface = {
  isLoading: true,
  error: null,
  redeemError: null,
  authUrl: '',
  externalUserId: null
};
export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(redeemAccessTokenAction.fulfilled as any, (state) => {
      state.redeemError = null;
    })
      .addCase(redeemAccessTokenAction.pending as any, (state) => {
        state.isLoading = true;
      }).addCase(redeemAccessTokenAction.rejected as any, (state, { payload }) => {
        state.redeemError = payload;
        state.isLoading = false;
      }).addCase(fetchOwnerAction.fulfilled as any, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.externalUserId = payload.externalUserId;
      }).addCase(fetchOwnerAction.rejected as any, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      }).addCase(fetchAuthUrlAction.fulfilled as any, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.authUrl = payload;
      }).addCase(fetchAuthUrlAction.pending as any, (state) => {
        state.isLoading = true;
      }).addCase(fetchAuthUrlAction.rejected as any, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      });
  }

});

export default calendarSlice.reducer;
export { fetchAuthUrlAction, redeemAccessTokenAction, fetchOwnerAction };