export const LogoCoaches = () => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.709 2.8692C15.5 3.07114 16.2011 3.52984 16.7018 4.17299C17.2024 4.81613 17.4742 5.60713 17.4742 6.42129C17.4742 7.23544 17.2024 8.02644 16.7018 8.66958C16.2011 9.31273 15.5 9.77143 14.709 9.97337"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.27399 10.0833C10.3049 10.0833 11.9513 8.44171 11.9513 6.41667C11.9513 4.39162 10.3049 2.75 8.27399 2.75C6.24307 2.75 4.59668 4.39162 4.59668 6.41667C4.59668 8.44171 6.24307 10.0833 8.27399 10.0833Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9509 19.3803C13.227 19.1513 14.2386 18.8023 14.7089 18.3333C14.775 17.7403 14.7089 16.8055 14.7089 16.8055C14.7089 15.9952 14.3215 15.218 13.6319 14.6449C11.454 12.8353 4.17392 12.8353 1.99608 14.6449C1.30645 15.218 0.919017 15.9952 0.919017 16.8055C0.919017 16.8055 0.852938 17.7403 0.919017 18.3333C1.38931 18.8023 2.40095 19.1513 3.677 19.3803"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3867 19.3803C19.6628 19.1513 20.6744 18.8023 21.1447 18.3333C21.2108 17.7403 21.1447 16.8056 21.1447 16.8056C21.1447 15.9952 20.7573 15.218 20.0677 14.645C19.6622 14.3081 19.08 14.0339 18.3867 13.8224"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="22.0639" height="22" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
