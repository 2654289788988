import React from 'react';

interface Props {}

export const LogoWealth: React.FC<Props> = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 0C5.1398 0 0 5.1398 0 11.5C0 17.8602 5.1398 23 11.5 23C17.8602 23 23 17.8602 23 11.5C23 5.1398 17.8602 0 11.5 0ZM11.5 21.0896C6.21469 21.0896 1.91041 16.7853 1.91041 11.5C1.91041 6.21469 6.21469 1.91041 11.5 1.91041C16.7853 1.91041 21.0896 6.21469 21.0896 11.5C21.0896 16.7853 16.7853 21.0896 11.5 21.0896Z"
      fill="currentColor"
    />
    <path
      d="M15.6073 15.0715C15.3585 14.6913 14.8422 14.5692 14.4714 14.8274C13.4106 15.5737 11.9883 14.8556 11.8569 14.818C11.3687 14.6913 10.7398 14.7147 10.1295 14.7992C10.2187 14.5974 10.2938 14.3909 10.3642 14.1703C10.552 13.5788 10.6506 12.978 10.66 12.4101H12.4061C12.8614 12.4101 13.2275 12.0439 13.2275 11.5886C13.2275 11.1333 12.8614 10.7672 12.4061 10.7672H10.4065C10.4065 10.7625 10.4018 10.7531 10.4018 10.7484C10.0122 9.69699 10.2422 7.87577 11.3875 7.71617C12.9177 7.50964 13.1993 9.09617 13.204 9.14781C13.2416 9.56556 13.5936 9.88944 14.0208 9.88944C14.814 9.88944 14.8469 9.13373 14.8328 8.96475C14.8234 8.88964 14.4291 5.90434 11.3828 6.07332C8.57118 6.23291 8.43506 9.87536 8.67914 10.7672H8.0924C7.6371 10.7672 7.27097 11.1333 7.27097 11.5886C7.27097 12.0439 7.6371 12.4101 8.0924 12.4101H9.0171C8.98424 13.6305 8.36934 14.9776 7.65118 15.4376C7.30383 15.6629 7.13955 16.1276 7.35077 16.4843C7.66995 17.0147 8.26608 16.9396 8.35057 16.9068C9.26118 16.6017 10.8195 16.245 11.4391 16.4092C11.5612 16.4421 13.5655 17.4607 15.3632 16.2027C15.7387 15.9445 15.8561 15.4517 15.6073 15.0715Z"
      fill="currentColor"
    />
  </svg>
);
