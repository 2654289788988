export const MailLogo = () => (
  <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.0307 0H3.47191C1.56143 0 0 1.55457 0 3.47191V24.3034C0 26.2214 1.56244 27.7753 3.47191 27.7753H36.0307C37.9412 27.7753 39.5026 26.2207 39.5026 24.3034V3.47191C39.5026 1.5541 37.9404 0 36.0307 0ZM35.4976 2.31461C34.3751 3.44081 21.1898 16.669 20.6481 17.2125C20.1957 17.6663 19.3071 17.6666 18.8545 17.2125L4.00504 2.31461H35.4976ZM2.31461 23.8779V3.89741L12.2724 13.8876L2.31461 23.8779ZM4.00504 25.4607L13.9065 15.527L17.2152 18.8465C18.5711 20.2068 20.9321 20.2063 22.2875 18.8465L25.5962 15.5271L35.4976 25.4607H4.00504ZM37.188 23.8779L27.2302 13.8876L37.188 3.89741V23.8779Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3.79381e-08"
        y1="13.8877"
        x2="37.7645"
        y2="13.8877"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#53DADA" />
        <stop offset="1" stopColor="#61D57F" />
      </linearGradient>
    </defs>
  </svg>
);
