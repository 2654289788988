'use client';

import { ToasterContainer } from "@otto-finance/ui";
import { useLazyFetchAccountsQuery } from 'api/accounts.api';
import { useLazyFinanceOverviewQuery } from 'api/finance.api';
import { useLazySettingsQuery } from "api/settings.api";
import { useLazyCurrentUserQuery } from "api/users.api";
import { UserInterface } from "common/interfaces";
import { ConfirmAlert } from 'common/otto-ui/confirm-alert/confirm-alert';
import { useIdentifyUser, usePageChange } from "common/roq-hooks";
import { AppNavigation } from "components/app-navigation";
import { SessionRoom } from "components/session-meeting/session-room";
import { useAppDispatch } from "configuration/redux/store";
import { ReactNode, useCallback, useEffect } from 'react';
import { updateUserCoachAction } from "slices/user-coach";
import { useAnalytics } from 'use-analytics';
import { useLoginHook } from 'views/login/hooks';
import { AppContext } from "./app.context";

const AppProvider = ({ children }: { children: ReactNode; }) => {
  const { userData, isLogged, isCustomer, currency } = useLoginHook();
  const [getFinanceOverview] = useLazyFinanceOverviewQuery();
  const [getAccounts] = useLazyFetchAccountsQuery();
  const { page } = useAnalytics();
  const [getSettings] = useLazySettingsQuery();
  const dispatch = useAppDispatch();
  const { identifyUser } = useIdentifyUser();
  const [getUser] = useLazyCurrentUserQuery();

  const onRouteChangeComplete = useCallback(() => page(), []);
  usePageChange('routeChangeComplete', onRouteChangeComplete);

  useEffect(() => {
    if (isLogged) {
      getUser({ id: userData.id });
      getSettings(undefined);
      if (isCustomer) {
        dispatch(updateUserCoachAction(userData as UserInterface));
        getFinanceOverview({ userId: userData.id, convertTo: currency });
        getAccounts({ userId: userData.id, convertTo: currency });
      }
      void identifyUser();
    }
    onRouteChangeComplete();
  }, [isLogged]);

  return (
    <AppContext.Provider value={{}}>
      {isLogged ? (
        <>
          <AppNavigation />
          <SessionRoom />
        </>
      ) : null}
      {children}
      <ToasterContainer placement="topRight" overrides={{
        Root: {
          style: () => ({
            zIndex: 999
          })
        }
      }} />
      <ConfirmAlert />
      {/* TODO: Create a hook to get survey form that is >= date. */}
      {/* {isLogged && router.route === ROUTE_ENUM.DASHBOARD && (
        <>
          <DelayComponent>
            <SurveyForms />
          </DelayComponent>
        </>
      )} */}

    </AppContext.Provider>
  );
};

export { AppProvider };
