import { AppointmentTranscription, AppointmentTranscriptionFormat } from "common/interfaces";
import { APPOINTMENT_TRANSCRIPTION_TYPE } from "enums";
import { textToArray } from "./text-to-array";

export const transformSessionTranscription = (transcript: AppointmentTranscription): AppointmentTranscriptionFormat => {
  return {
    summary: transcript.summaries.find(f => f.type === APPOINTMENT_TRANSCRIPTION_TYPE.CUSTOMER)?.text.replace('\n', '<br/><br/>'),
    keyPoints: textToArray(transcript.summaries.find(f => f.type === APPOINTMENT_TRANSCRIPTION_TYPE.KEY_POINTS)?.text, '\n').filter(f => f[0] === '-' || f[1] === '.').map(i => i.slice(2)) || [],
    tasks:
      textToArray(transcript.summaries.find(f => f.type === APPOINTMENT_TRANSCRIPTION_TYPE.TASKS)?.text, '\n')
        .map(i => {
          const t = i.split(":", 2);
          return {
            title: t[0].slice(2),
            description: t[1]
          };
        })
      || []
  };
};
