export const LogoAdmins = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.06836 9.62513V10.3126C9.06836 10.6923 9.35698 11.0002 9.71298 11.0002H16.1592C16.5152 11.0002 16.8038 10.6923 16.8038 10.3126V9.62513C16.8038 7.35057 15.0688 5.50006 12.9361 5.50006C10.8034 5.50006 9.06836 7.35057 9.06836 9.62513ZM12.9361 6.87508C14.3579 6.87508 15.5146 8.10876 15.5146 9.62513H10.3576C10.3576 8.10876 11.5143 6.87508 12.9361 6.87508Z"
      fill="currentColor"
      fillOpacity="0.7"
    />
    <path
      d="M4.36041 13.7494H0.644624C0.28862 13.7494 0 14.0573 0 14.4369V19.937C0 20.3167 0.28862 20.6246 0.644624 20.6246H4.51237C4.86837 20.6246 5.15699 20.3167 5.15699 19.937V19.3364C6.33029 19.4927 7.47665 19.8572 8.50723 20.4068C9.93469 21.168 11.7034 22.011 13.2884 21.9995C15.316 21.9856 16.8907 20.8016 18.6722 18.7043C19.1989 18.1694 21.4157 15.1333 21.8639 14.5194C22.0739 14.2319 22.037 13.8196 21.7799 13.5796C20.446 12.3348 18.4155 12.5114 17.2889 13.9707L16.3784 15.0886C14.0631 14.9148 12.896 14.1405 11.8517 13.4476C11.0007 12.883 10.1969 12.3498 9.00656 12.2957C7.79514 12.2409 6.35784 12.6901 4.36041 13.7494ZM3.86774 19.2495H1.28925V15.1245H3.86774V19.2495ZM18.2803 14.8497C18.823 14.1425 19.7025 13.962 20.4107 14.2707C19.9166 14.9445 19.4581 15.5634 19.0274 16.1279C18.8151 15.7104 18.4649 15.371 17.9826 15.2158C18.323 14.7975 18.2707 14.8622 18.2803 14.8497ZM17.3766 16.4995C17.9371 16.4995 17.9807 17.0082 17.9807 17.1641C17.9807 17.5342 17.7821 17.8745 17.3361 17.8745C15.0398 17.8745 12.502 17.0693 12.4769 17.0613C12.136 16.9517 11.7764 17.1576 11.6737 17.5212C11.571 17.8847 11.764 18.2683 12.1049 18.3778C12.2011 18.4087 14.1981 19.0436 16.3531 19.2099C15.3182 20.1516 14.3655 20.6171 13.2802 20.6246C11.9418 20.6355 10.2938 19.8223 9.08383 19.177C7.8767 18.5332 6.5303 18.1142 5.15703 17.9513V14.8637C8.7423 13.0043 9.66798 13.6183 11.1707 14.6153C12.436 15.4547 14.0107 16.4995 17.3766 16.4995Z"
      fill="currentColor"
      fillOpacity="0.7"
    />
    <path
      d="M15.5144 2.75005C15.5144 1.23367 14.3577 0 12.9359 0C11.5141 0 10.3574 1.23367 10.3574 2.75005C10.3574 4.26642 11.5141 5.50009 12.9359 5.50009C14.3577 5.50009 15.5144 4.26642 15.5144 2.75005ZM12.9359 4.12507C12.225 4.12507 11.6467 3.50823 11.6467 2.75005C11.6467 1.99186 12.225 1.37502 12.9359 1.37502C13.6468 1.37502 14.2252 1.99186 14.2252 2.75005C14.2252 3.50823 13.6468 4.12507 12.9359 4.12507Z"
      fill="currentColor"
      fillOpacity="0.7"
    />
  </svg>
);
