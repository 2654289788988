import * as yup from 'yup';

type YupSchemaFields = {
  name: string;
  validationType: string;
  validationTypeError?: string;
  validations: {
    type: string;
    params: string[];
  }[];
};

export const getYupSchema = (fields: YupSchemaFields[]): yup.AnySchema => {
  const yupSchema = fields.reduce((schema, field) => {
    const { name, validationType, validationTypeError, validations = [] } = field;
    const isObject = name.indexOf('.') >= 0;

    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]().typeError(validationTypeError || '');
    validations.forEach(validation => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });

    if (!isObject) {
      return schema.concat(yup.object().shape({ [name]: validator }));
    }

    const reversePath = name.split('.').reverse();
    const currNestedObject = reversePath.slice(1).reduce((yupObj, path) => {
      if (!isNaN(Number(path))) {
        return { array: yup.array().of(yup.object().shape(yupObj)) };
      }
      if (yupObj.array) {
        return { [path]: yupObj.array };
      }
      return { [path]: yup.object().shape(yupObj) };
    }, { [reversePath[0]]: validator });

    const newSchema = yup.object().shape(currNestedObject);
    return schema.concat(newSchema);
  }, yup.object().shape({}));

  return yupSchema;
};
