import { IconProps } from 'common/otto-ui/icons';

export const LogoEvents: React.FC<IconProps> = ({ width = 23, height = 15, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.625" y="0.625" width="21.25" height="13.75" rx="1.875" stroke="currentColor" strokeWidth="1.25" />
    <path
      d="M14.0625 8.04127C14.4792 7.8007 14.4792 7.1993 14.0625 6.95873L10.3125 4.79367C9.89583 4.55311 9.375 4.85381 9.375 5.33494V9.66506C9.375 10.1462 9.89583 10.4469 10.3125 10.2063L14.0625 8.04127Z"
      stroke={color}
      strokeWidth="1.25"
    />
  </svg>
);
