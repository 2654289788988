import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'api/auth.api';
import { customerProfileApi } from 'api/customer-profile.api';
import { userApi } from 'api/user.api';
import { StoreInterface } from 'configuration/redux/store';
import omit from 'lodash/omit';
import { CURRENCY, UserChannelEnum, UserTypeEnum } from 'enums';
import { logoutOnExpiredAction } from 'slices/auth-management/actions';
import { usersApi } from 'api';
import { saveState } from 'configuration/redux/local-store';
import { ageFromDate } from 'utils/dates';
import { CustomerProfileInterface, UserInterface } from 'common/interfaces';
import { getCustomerCoachConversation } from 'utils/get-customer-coach-conversation';
import { TierEnum } from 'configuration/data';
import { partners } from 'configuration/data/partners';

interface AuthStateErrorInterface {
  message: string;
}

export interface AuthStateUserDataInterface extends UserInterface {
  fullName?: string;
  currency?: string;
  country?: string;
  age?: number;
  birthday?: string;
  isMarried?: boolean;
  conversationId?: string;
}

export interface AuthStateInterface {
  isLoading: boolean;
  error: AuthStateErrorInterface | null;
  accessToken?: string;
  refreshToken?: string;
  userData: Partial<AuthStateUserDataInterface>;
  isAdmin: boolean;
  isCoach: boolean;
  isCustomer: boolean;
  isLogged: boolean;
  isTier: {
    lite: boolean;
    legacyLite: boolean;
    benefitTrial: boolean;
    otto: boolean;
    nova: boolean;
    premium: boolean;
    company: boolean;
  }
}

const initialUserDataState = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  avatar: '',
  timezone: '',
  coachMetaInfoId: '',
  type: UserTypeEnum.CUSTOMER,
  avatarImageIdentifier: '',
  calendarConnected: false,
  currency: CURRENCY.GBP,
  partnerId: null,
  tier: {
    id: null,
    name: null,
    tier: null,
    features: {
      full: [],
      limited: []
    }
  },
  conversationId: null
};

export const authInitialState: AuthStateInterface = {
  isLoading: false,
  error: null,
  userData: initialUserDataState,
  isAdmin: false,
  isCoach: false,
  isCustomer: false,
  isLogged: false,
  isTier: {
    lite: false,
    legacyLite: false,
    benefitTrial: false,
    otto: false,
    nova: false,
    premium: false,
    company: false
  }
};

export interface AuthTokenInterface {
  accessToken: string;
  refreshToken: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  type: string;
  avatar?: string;
}

const updateProfileData = (state, userProfile: Partial<CustomerProfileInterface> | null) => {
  state.userData.currency = userProfile?.currency || CURRENCY.GBP;
  state.userData.country = userProfile?.country;
  if (userProfile?.birthday) {
    state.userData.birthday = userProfile.birthday;
    state.userData.age = ageFromDate(state.userData.birthday);
  }

  state.userData.isMarried = userProfile?.isMarried;
  if (state.userData.userProfile !== null) {
    state.userData.userProfile = {
      ...state.userData.userProfile,
      ...userProfile
    };
  } else {
    state.userData.userProfile = userProfile;
  }
};

const updateTermsAcceptedAtReducer = (state, action) => {
  state.userData.termsAcceptedAt = action.payload.termsAcceptedAt;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    updateUserType: (state, { payload }: PayloadAction<UserTypeEnum>) => {
      state.userData.type = UserTypeEnum.ADMIN;
      state.isAdmin = payload === UserTypeEnum.ADMIN;
      state.isCoach = payload === UserTypeEnum.COACH;
      state.isCustomer = payload === UserTypeEnum.CUSTOMER;
    },
    updateUserProfile: (state, { payload }: PayloadAction<Partial<CustomerProfileInterface>>) => {
      updateProfileData(state, payload);
    },
    setUserProfileCurrency: (state, { payload }) => {
      state.userData.userProfile.currency = payload;
    },
    updateTermsAcceptedAt: (state, action) => {
      updateTermsAcceptedAtReducer(state, action);
    },
    incrementOrDecrementSession: (state, action: PayloadAction<number>) => {
      if (state.userData.channel === UserChannelEnum.BENEFITS) {
        if (state.userData.benefit && state.userData.benefit?.sessions !== null) {
          state.userData.benefit.sessions = Math.max(0, state.userData.benefit.sessions + action.payload);
        }
      }
    },
    updateAvatar: (state, { payload }: PayloadAction<{
      id: string;
      url: string;
    }>) => {
      state.userData.avatar = payload.url;
      state.userData.avatarImageIdentifier = payload.id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutOnExpiredAction, (state) => {
        state = authInitialState;
      })
      .addMatcher(userApi.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
        if (state.userData.id === payload.id) {
          state.userData = {
            ...state.userData,
            ...transformUserData(payload)
          };
        }
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
        state.accessToken = '';
        state.refreshToken = '';
        state.isLogged = false;
      })
      .addMatcher(authApi.endpoints.acceptInvite.matchFulfilled, (state, { payload }) => {
        storeAuthData(state, payload);
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        storeAuthData(state, payload);
      })
      .addMatcher(usersApi.endpoints.currentUser.matchFulfilled, (state, { payload }) => {
        storeAuthData(state, { user: payload });
      })
      .addMatcher(authApi.endpoints.tokenRefresh.matchFulfilled, (state, { payload }) => {
        if (payload && payload?.accessToken) {
          state.accessToken = payload.accessToken;
        }
      }).addMatcher(customerProfileApi.endpoints.saveProfile.matchFulfilled, (state, { payload, meta: { arg: { originalArgs } } }) => {
        if (payload && originalArgs?.data) {
          updateProfileData(state, omit(originalArgs?.data, 'userId'));
        }
      });
  },
});

const storeAuthData = (state: AuthStateInterface, payload: { user: UserInterface; accessToken?: string; refreshToken?: string; }) => {
  state.error = null;
  state.accessToken = payload?.accessToken || state.accessToken;
  state.refreshToken = payload?.refreshToken || state.refreshToken;
  const { type, tier } = payload.user;
  state.userData = {
    ...state.userData,
    ...transformUserData(payload.user)
  };
  state.isAdmin = type === UserTypeEnum.ADMIN;
  state.isCoach = type === UserTypeEnum.COACH;
  state.isCustomer = type === UserTypeEnum.CUSTOMER;

  if (state.isCustomer) {
    state.userData.conversationId = getCustomerCoachConversation(payload.user);
  }

  updateProfileData(state, payload.user?.userProfile);
  state.isLoading = false;
  state.isLogged = true;
  state.isTier = {
    lite: TierEnum.LITE === tier?.name,
    legacyLite: TierEnum.LEGACY_LITE === tier?.name,
    benefitTrial: TierEnum.BENEFIT_TRIAL === tier?.name,
    otto: TierEnum.OTTO === tier?.name,
    nova: payload.user?.partnerId === partners.nova,
    premium: TierEnum.PREMIUM === tier?.name,
    company: TierEnum.CHURNED === tier?.name,
  }
  saveState({ auth: state });
};

const transformUserData = (data: UserInterface) => {
  return {
    ...data,
    fullName: `${data.firstName} ${data.lastName}`
  };
};

export default authSlice.reducer;

export { logoutOnExpiredAction };

export const { updateUserType, updateAvatar, setUserProfileCurrency, updateTermsAcceptedAt, incrementOrDecrementSession } = authSlice.actions;

const selectAuth = (state: StoreInterface) => state.auth;

export const userDataSelector = createSelector(selectAuth, v => v.userData);
export const tierSelector = createSelector(selectAuth, v => v.isTier);
