export const LogoActions = () => (
  <svg width="26" height="25" viewBox="0 0 20 25" fill="#2d8e8e" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.79658 9.2199L6.59425 6.91488L5.46875 8.24503L8.79658 11.5741L14.5831 5.78634L13.5093 4.60986L8.79658 9.2199Z"
      fill="currentColor"
    />
    <path
      d="M8.79658 17.6721L6.59425 15.367L5.46875 16.6972L8.79658 20.0262L14.5831 14.2385L13.5093 13.062L8.79658 17.6721Z"
      fill="currentColor"
    />
    <path
      d="M17.2225 0H2.47041C1.11444 0 0.0117188 1.10272 0.0117188 2.45869V22.1282C0.0117188 23.4842 1.11444 24.5869 2.47041 24.5869H17.2225C18.5785 24.5869 19.6812 23.4842 19.6812 22.1282V2.45869C19.6812 1.10272 18.5785 0 17.2225 0ZM1.80426 22.666V1.92085H17.9394V22.666H1.80426Z"
      fill="currentColor"
    />
  </svg>
);
