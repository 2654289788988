import { Action, configureStore, Store, ThunkDispatch } from '@reduxjs/toolkit';
import { loadState } from 'configuration/redux/local-store';
import reducer, { middlewares, RootState } from 'configuration/redux/reducer';
import { useDispatch } from 'react-redux';
import { ActionsStateInterface } from 'slices/actions/actions.slice';
import { AdminsStateInterface } from 'slices/admins';
import { AssetsStateInterface } from 'slices/assets';
import { AuthStateInterface } from 'slices/auth-management';
import { BenefitsStateInterface } from 'slices/benefits';
import { CalendarStateInterface } from "slices/calendar/calendar.slice";
import { ChatStateInterface } from 'slices/chat';
import { CoachNotepadStateInterface } from 'slices/coach-notepad';
import { ConfirmAlertInterface } from 'slices/confirm-alert';
import { DocumentsStateInterface } from 'slices/documents';
import { EventsStateInterface } from 'slices/events';
import { FinanceAuthStateInterface } from 'slices/finance-auth';
import { FinanceOverviewStateInterface } from 'slices/finance-overview';
import { InvestmentsStateInterface } from 'slices/investments';
import { InvestmentsWatchlistStateInterface } from 'slices/investments-watchlist/investments-watchlist.slice';
import { PlatformStateInterface } from 'slices/platform/platform.slice';
import { SeenFunctionalityInterface } from 'slices/seen-functionality/seen-functionality.slice';
import { SessionStateInterface } from 'slices/session';
import { SessionsStateInterface } from 'slices/sessions';
import { SettingsStateInterface } from 'slices/settings';
import { SurveyStateInterface } from 'slices/survey/survey.slice';
import { TransactionsStateInterface } from 'slices/transactions';
import { UiInterface } from 'slices/ui';
import { UserCoachStateInterface } from 'slices/user-coach';

export interface StoreInterface {
  auth: AuthStateInterface;
  assets: AssetsStateInterface;
  platform: PlatformStateInterface;
  chat: ChatStateInterface;
  actions: ActionsStateInterface;
  sessions: SessionsStateInterface;
  admins: AdminsStateInterface;
  settings: SettingsStateInterface;
  session: SessionStateInterface;
  calendar: CalendarStateInterface;
  survey: SurveyStateInterface;
  seenFunctionality: SeenFunctionalityInterface;
  benefits: BenefitsStateInterface;
  coachNotepad: CoachNotepadStateInterface;
  confirmAlert: ConfirmAlertInterface;
  events: EventsStateInterface;
  financeOverview: FinanceOverviewStateInterface;
  financeAuth: FinanceAuthStateInterface;
  investments: InvestmentsStateInterface;
  investmentsWatchlist: InvestmentsWatchlistStateInterface;
  ui: UiInterface;
  userCoach: UserCoachStateInterface;
  transactions: TransactionsStateInterface;
  documents: DocumentsStateInterface;
}

/**
 * Increment this redux version whenever a change is
 * made to the structure of the redux store
 */
const REDUX_VERSION = '11';
const persistedState = loadState(REDUX_VERSION);

const store = configureStore({
  reducer,
  middleware: (gDM) => gDM({
  // immutableCheck: {
  //   ignoredPaths: ['chat','api','investments']
  // },
    immutableCheck: false,
    serializableCheck: false,
  }).concat(middlewares),
  preloadedState: persistedState,
  devTools: process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
});

export interface ThunkExtraArguments {
  store: Store;
}

export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppDispatch = () : ThunkAppDispatch => useDispatch<ThunkAppDispatch>();

export default store;
