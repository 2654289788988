import { differenceInMilliseconds } from 'date-fns';

export const calculateTimeRemaining = (start: Date, end = new Date()): string | boolean => {
  const diff = differenceInMilliseconds(start, end);

  if (diff > 0) {
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    if (days > 0) {
      return `${days} day${days === 1 ? '' : 's'} ${hours} hour${hours === 1 ? '' : 's'} ${minutes} minute${
        minutes === 1 ? '' : 's'
      } ${seconds} second${seconds === 1 ? '' : 's'}`;
    }

    if (hours > 0) {
      return `${hours} hour${hours === 1 ? '' : 's'} ${minutes} minute${minutes === 1 ? '' : 's'} ${seconds} second${
        seconds === 1 ? '' : 's'
      }`;
    }

    if (minutes > 0) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ${seconds} second${seconds === 1 ? '' : 's'}`;
    }

    if (seconds > 0) {
      return `${seconds} second${seconds === 1 ? '' : 's'}`;
    }

    return false;
  }
};
