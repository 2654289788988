const ALL_ROLES = ['coach', 'admin', 'customer'];
const COACH_ADMIN = ['coach', 'admin'];
const ADMIN = ['admin'];
const CUSTOMER = ['customer'];

const acls = {
  users: {
    create: ADMIN,
    read: COACH_ADMIN,
    update: ADMIN,
    delete: ADMIN,
  },
  home: {
    create: ALL_ROLES,
    read: ALL_ROLES,
    update: ALL_ROLES,
    delete: ALL_ROLES,
  },
  coach: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  roadmap: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  wealth: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  sessions: {
    create: COACH_ADMIN,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  messages: {
    create: COACH_ADMIN,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  experts: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  coaches: {
    create: ADMIN,
    read: ADMIN,
    update: ADMIN,
    delete: ADMIN,
  },
  admins: {
    create: ADMIN,
    read: ADMIN,
    update: ADMIN,
    delete: ADMIN,
  },
  actions: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  calendar: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  events: {
    read: ['customer', 'coach'],
  },
  learn: {
    read: ['customer', 'coach'],
  },
  feedback: {
    create: ALL_ROLES,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  session: {
    create: COACH_ADMIN,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  session_room: {
    read: ['customer', 'coach'],
  },
  connect_calendar: {
    create: ['coach'],
    read: ['coach'],
    update: ['coach'],
    delete: ['coach'],
  },
  welcome: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  provide_email: {
    create: ADMIN,
    read: ADMIN,
    update: ADMIN,
    delete: ADMIN,
  },
  availability: {
    create: ['coach'],
    read: ['coach'],
    update: ['coach'],
    delete: ['coach'],
  },
  ottomate: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  dashboard: {
    create: ALL_ROLES,
    read: ALL_ROLES,
    update: ALL_ROLES,
    delete: ALL_ROLES,
  },
  settings: {
    read: ALL_ROLES
  },
  profile: {
    read: ALL_ROLES
  },
  documents: {
    create: ALL_ROLES,
    read: ALL_ROLES,
    update: ALL_ROLES,
    delete: ALL_ROLES
  },
};

export default acls;
