export const LogoDashboard = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0005 0.768555C6.22184 0.768555 0.707031 6.26185 0.707031 13.014C0.707031 17.4459 3.14406 21.5514 7.06706 23.7283C7.174 23.7876 7.29425 23.8187 7.41656 23.8187H18.5844C18.7066 23.8187 18.8269 23.7876 18.9339 23.7283C22.8569 21.5514 25.2939 17.4459 25.2939 13.014C25.2939 6.26185 19.7791 0.768555 13.0005 0.768555ZM18.3948 22.3781H7.60615C4.23424 20.4334 2.14767 16.8624 2.14767 13.014C2.14767 7.05622 7.01621 2.20919 13.0005 2.20919C18.9847 2.20919 23.8533 7.05622 23.8533 13.014C23.8533 16.8624 21.7667 20.4334 18.3948 22.3781Z"
      fill="currentColor"
    />
    <path
      d="M19.6783 6.45573C19.661 6.43378 19.6033 6.37135 19.5586 6.33606C17.8681 4.67548 15.5522 3.64941 13.0009 3.64941C10.4495 3.64941 8.13367 4.67548 6.44308 6.33606C6.39842 6.37135 6.35866 6.41111 6.32341 6.45573C4.66279 8.14636 3.63672 10.4622 3.63672 13.0136C3.63672 13.4114 3.95923 13.7339 4.35704 13.7339H7.23831C7.63612 13.7339 7.95863 13.4114 7.95863 13.0136C7.95863 12.6157 7.63612 12.2932 7.23831 12.2932H5.11044C5.25936 10.6485 5.91273 9.14727 6.91465 7.94602L8.4168 9.44817C8.55745 9.58882 8.74176 9.65913 8.92611 9.65913C9.11042 9.65913 9.29482 9.58878 9.43543 9.44817C9.71674 9.16686 9.71674 8.7108 9.43543 8.4295L7.93327 6.92734C9.08814 5.96413 10.5202 5.3231 12.0906 5.14263C11.6639 7.07184 10.8399 10.8865 10.8399 11.5729C10.8399 12.7645 11.8093 13.7339 13.0009 13.7339C14.1924 13.7339 15.1618 12.7645 15.1618 11.5729C15.1618 10.8864 14.3377 7.07155 13.9111 5.14239C15.4815 5.32281 16.9137 5.96394 18.0686 6.92715L16.5663 8.4295C16.285 8.7108 16.285 9.16686 16.5663 9.44817C16.7069 9.58882 16.8913 9.65913 17.0756 9.65913C17.2599 9.65913 17.4443 9.58878 17.5849 9.44817L19.0873 7.94578C20.0892 9.14708 20.743 10.6483 20.8919 12.2932H18.7634C18.3656 12.2932 18.0431 12.6157 18.0431 13.0136C18.0431 13.4114 18.3656 13.7339 18.7634 13.7339H21.6447C22.0425 13.7339 22.365 13.4114 22.365 13.0136C22.365 10.4622 21.3389 8.14636 19.6783 6.45573ZM13.0009 12.2932C12.6037 12.2932 12.2805 11.9701 12.2805 11.5732C12.2827 11.2708 12.5922 9.67853 13.0009 7.74635C13.4096 9.67853 13.719 11.2707 13.7212 11.5732C13.7211 11.9702 13.398 12.2932 13.0009 12.2932Z"
      fill="currentColor"
    />
    <path
      d="M14.4407 16.6152H11.5594C10.3678 16.6152 9.39844 17.5846 9.39844 18.7762C9.39844 19.9677 10.3678 20.9371 11.5594 20.9371H14.4407C15.6322 20.9371 16.6016 19.9677 16.6016 18.7762C16.6016 17.5846 15.6322 16.6152 14.4407 16.6152ZM14.4407 19.4965H11.5594C11.1622 19.4965 10.8391 19.1734 10.8391 18.7762C10.8391 18.379 11.1622 18.0559 11.5594 18.0559H14.4407C14.8379 18.0559 15.161 18.379 15.161 18.7762C15.161 19.1734 14.8379 19.4965 14.4407 19.4965Z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="24.5869" height="24.5869" fill="white" transform="translate(0.707031)" />
      </clipPath>
    </defs>
  </svg>
);
