export const LogoMessages = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3464 0C12.3399 0.00658755 12.3267 0.00658755 12.3069 0.00658755C5.64031 0.00658755 0.21875 5.42814 0.21875 12.0947C0.21875 14.8154 1.1476 17.457 2.84719 19.5848L1.08831 23.6691C0.943381 24.005 1.10148 24.3937 1.43086 24.532C1.54944 24.5847 1.68119 24.5979 1.80635 24.5782L8.25557 23.4451C9.54673 23.9128 10.9038 24.15 12.274 24.1434C18.9406 24.1434 24.3621 18.7218 24.3621 12.0552C24.3753 5.40838 18.9933 0.00658755 12.3464 0ZM12.2806 22.8325C11.0092 22.8325 9.75095 22.6019 8.5586 22.1539C8.44661 22.1078 8.32803 22.1012 8.20946 22.121L2.78131 23.0696L4.2174 19.7297C4.31621 19.4992 4.27669 19.2291 4.112 19.038C3.33466 18.1355 2.71543 17.1079 2.28066 15.9946C1.79318 14.7495 1.54285 13.4254 1.54285 12.0882C1.54285 6.14619 6.37812 1.31751 12.3135 1.31751C18.2357 1.30434 23.0446 6.09348 23.0512 12.0157C23.0512 12.0289 23.0512 12.042 23.0512 12.0552C23.0512 18.0038 18.216 22.8325 12.2806 22.8325Z"
      fill="currentColor"
    />
    <path
      d="M11.7707 9.66406H8.21344C7.85113 9.66406 7.55469 9.9605 7.55469 10.3228C7.55469 10.6851 7.85113 10.9816 8.21344 10.9816H11.7707C12.133 10.9816 12.4295 10.6851 12.4295 10.3228C12.4295 9.9605 12.133 9.66406 11.7707 9.66406Z"
      fill="currentColor"
    />
    <path
      d="M16.382 12.9578H8.21344C7.85113 12.9578 7.55469 13.2542 7.55469 13.6165C7.55469 13.9788 7.85113 14.2753 8.21344 14.2753H16.382C16.7443 14.2753 17.0408 13.9788 17.0408 13.6165C17.0408 13.2542 16.7443 12.9578 16.382 12.9578Z"
      fill="currentColor"
    />

    <defs>
      <clipPath id="clip0">
        <rect width="24.5869" height="24.5869" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
