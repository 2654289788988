export enum DATE_FORMAT {
  /**
   * 2022-12-31
   */
  DEFAULT = 'yyyy-MM-dd',
  /**
   * 2022-12-31
   */
  DEFAULT_UK = 'dd/MM/yyyy',
  /**
   *  January 1st, 2022
   */
  READABLE = 'MMMM do, yyyy',
  /**
   *  THURSDAY 19TH OCT
   */
  DAY_MONTH = 'EEEE do LLL',
  /**
   * 1st Jan 2022
   */
  DATE_SHORT = 'do LLL yyyy',
  /**
   * 4:32 PM
   */
  TIME = 'h:mm a',
  /**
   * 4:32
   */
  TIME_SHORT = 'h:mm',
  /**
   * Weekday e.g. Monday
   */
  WEEKDAY = 'EEEE',
  /**
   * Short Weekday e.g. Mon
   */
  WEEKDAY_SHORT = 'ccc',
  /**
   * Aug 27
   */
  MONTH_DAY = 'LLL dd',
  /**
   * Aug 27 4:32 PM
   */
  MONTH_DAY_TIME = 'LLL dd h:mm a',
  /**
   * Aug 27, 2012 4:32 PM
   */
  YEAR_MONTH_DAY_TIME = 'LLL dd, yyyy h:mm a',
  /**
   * Jan, Feb, ..., Dec
   */
  MONTH_SHORT = 'MMM',
  /**
   * 2022-12-31
   */
  DEFAULT_2 = 'dd/MM/yyyy',
  /**
   * 2022-12-31
   */
  DEFAULT_SHORT = 'yyyy-MM',
  /**
    * January 2023
    */
  MONTH_YEAR = "MMMM yyyy",
  /**
    * Jan 2023
    */
  MONTH_YEAR_SHORT = "MMM yyyy",
  /**
    * 01
    */
  DAY_NUMBER = 'dd',
  /**
    * Mon Jul 29 2024
    */
  FULL_DATE = 'ccc MMM dd yyyy',
}
