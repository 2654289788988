import { LabelMedium } from '@otto-finance/ui';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';

const classes = {
  wrapper: clsx('h-[100vh] w-[100vw] flex items-center justify-center flex-col space-y-6'),
};

export const ErrorBoundary = () => {
  const { t } = useTranslation('common');
  return (
    <div className={classes.wrapper}>
      <LabelMedium className="!font-medium">{t('errors.errorBoundary')}</LabelMedium>
    </div>
  );
};
