import { ExpertInterface } from 'views/experts/types';

export const EXPERTS_DATA: ExpertInterface[] = [
  {
    id: '66b889fd-f9c1-4ca2-9996-930a659e25aa',
    firstName: 'Abbie',
    lastName: 'Glew',
    title: 'Chartered Accountant',
    email: 'abbie@orendacollective.co.uk',
    summary: '11 years, Chartered Accountant Regulated by the Institute of Chartered Accountants England and Wales',
    expertise: [
      {
        label: 'Accounting',
        icon: 'calculator',
      },
    ],
    offering: 'Get a free 20 minute consultation to see if Abbie can help you',
    typicalSessions: [
      {
        title: 'Starting a Business',
        detail: 'I have a company/am self-employed and I have no idea what I am supposed to do?'
      },
      {
        title: 'Expenses',
        detail: 'What expenses can I put through?',
      },
      {
        title: 'VAT',
        detail: 'How does VAT work?',
      },
      {
        title: 'Cashflows',
        detail: 'How do I calculate cashflows?',
      },
      {
        title: 'Budget',
        detail: 'How do I budget?',
      },
      {
        title: 'Investing',
        detail: 'Should I invest in X?',
      },
    ],
    about: 'Abbie provides a holistic approach to the financial aspects of your business. She works with current or aspiring business owners to strategise for growth, achieve financial goals and form valuable business connections. Accounting and tax is a crucial component of running a successful business. Understanding your tax and financial position enables you to focus on your service or product offering and make sound business decisions. Financial Business Coaching enables small business owners or individuals to understand their finances, define monetary goals and create an actionable strategy, to facilitate growth in their business or life.',
    testimonials: [],
    imageUrl: 'https://storage.googleapis.com/roq-v2-otto-finance-production/experts/abbie_expert.png',
  },
  {
    id: 'abb4d950-04f1-4b90-8b96-e80ba5ba6744',
    firstName: 'Andrew',
    lastName: 'Frankland',
    title: 'Chartered Financial Planner APFS',
    email: 'andrew@modofinancial.co.uk',
    summary:
      'Pension and Retirement Advisor, able to help optimize current pension position to help you get the retirement you want.',
    expertise: [
      {
        label: 'Retirement',
        icon: 'tax',
      },
    ],
    offering: 'Get a free 20 minute consultation to see if Andrew can help you',
    about:
      "Andrew has nearly a decades worth of financial planning experience and has previously held executive positions within large financial planning practices. As a pensions and retirement planning expert, he started his own firm in 2020 with a vision to use technology to bring people's money to life.",
    typicalSessions: [
      {
        title: 'Future goal setting',
        detail: 'Set your future goals',
      },
      {
        title: 'Financial Habits',
        detail: 'Set your financial habits',
      },
      {
        title: 'Views on Money',
        detail: 'Views on Money, Investments, and Savings',
      },
    ],
    testimonials: [],
    imageUrl: 'https://storage.googleapis.com/roq-v2-otto-finance-production/experts/andrew_expert.png',
  },
  {
    id: 'e1ed26d0-004e-4673-8e6b-aecf2348a6b2',
    firstName: 'Owain',
    lastName: 'Williams',
    email: 'owain.williams@williamsfs.co.uk',
    summary: 'Qualified Mortgage Advisor, able to help understand and find the best mortgage package for you.',
    title: 'CeMAP',
    offering: 'Get a free 20 minute consultation to see if Owain can help you',
    expertise: [
      {
        label: 'Mortgages',
        icon: 'home',
      },
    ],
    about:
      'Joining Financial Services straight after leaving education in 2011, Owain gained vital experience through working in Retail and Private Banking. Alongside this, Owain also worked as a whole of market Mortgage Advisor. During this time Owain spotted a gap in the market for expert advice with top quality customer service. Owain set up Williams Financial Services to help clients through their mortgage journey as easily and as stress free as possible.',
    typicalSessions: [
      {
        title: 'Mortgage Advice',
        detail: 'How much can I borrow for my first home?',
      },
      {
        title: 'Investment Advice',
        detail: 'How do I buy an investment property?',
      },
      {
        title: 'Savings',
        detail: 'How much do I need to save to build my dream home?',
      },
      {
        title: 'Interest Rates',
        detail: 'What is the interest rate for my mortgage?',
      },
    ],
    testimonials: [],
    imageUrl: 'https://storage.googleapis.com/roq-v2-otto-finance-production/experts/owain_expert.png',
  },
  {
    id: 'b807b787-551a-4cde-b826-ecca3fe65581',
    firstName: 'Peter',
    lastName: 'Deane',
    offering: '',
    email: 'psdeane0512@gmail.com',
    title: 'Estate Planner & Financial Coach',
    summary: 'I help people arrange Wills, Trusts and Lasting Powers of Attorney.',
    expertise: [
      {
        label: 'Estate Planning',
        icon: 'tax',
      },
    ],
    imageUrl: '/static/experts/peter-deane.jpg',
    about:
      `I've had a successful career in Financial Services in various commercial roles with some of the biggest and most famous companies in the Sector. 
      <br /><br />
      As an Estate Planning Consultant I use my coaching skills to help people develop plans and strategies to manage their wealth and provide safe, secure, and lasting legacies for their loved ones`,
    typicalSessions: [
      {
        title: 'Arranging a Will, from the simple to the more complicated.',
        detail: '',
      },
      {
        title: 'Arranging Trusts to work alongside wills to provide additional protection against Inheritance Tax and care costs.',
        detail: '',
      },
      {
        title: 'Arranging Lasting Powers of Attorney for Health and Financial Matters.',
        detail: '',
      },
    ],
  },
  {
    id: 'b807b787-551a-4cde-b826-ecca3fe65581',
    firstName: 'David',
    lastName: 'Clouter',
    offering: '',
    email: 'david_cambs@yahoo.co.uk',
    title: 'Property Investor and Mentor',
    summary: `40+ years’ experience. Which property strategy works best for you? Helping you succeed at every step in your property journey.`,
    expertise: [
      {
        label: 'Property',
        icon: 'tax',
      },
    ],
    imageUrl: '/static/experts/David-Clouter.jpg',
    about:
      `David works with both early stage and experienced investors to achieve their desired outcomes. 
      Looking at where you are now, and where you want to be, he can advise the best strategy for you, 
      the steps you need to follow, and common mistakes to avoid. Work together to map out a bespoke plan, 
      and then assist every step of the way if required. <br />David's 40+ year property journey has included Buy-to-Let, Houses in Multiple Occupation, Serviced Accommodation, Rent-to-Rent, Property Refurbs, Flips, and even Hotels.`,
    typicalSessions: [
      {
        title: 'Choosing a Strategy',
        detail: 'What is the best property strategy for me?',
      },
      {
        title: 'Property Cycles',
        detail: 'How to make money even when prices are falling.',
      },
      {
        title: 'Teamwork',
        detail: 'Build your power team. When to outsource.',
      },
      {
        title: 'Negotiation',
        detail: 'Always buy at the right price.',
      },
      {
        title: 'Scaling',
        detail: 'Grow your property business effectively.',
      },
    ],
  }
];
