
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { settingsApi } from 'api';
import { saveState } from 'configuration/redux/local-store';
import { StoreInterface } from 'configuration/redux/store';
import { overrideThemeUtil, saveThemeOverride } from './ui.util';

export const SETTINGS_VER = 3;

export enum UiThemeEnum {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum WealthTabsEnum {
  OVERVIEW = 'overview',
  CASHFLOW = 'cashflow',
  INVESTMENTS = 'investments',
  MANAGE = 'manage',
}
type TourPages = 'dashboard' | 'investments' | 'investmentsAnalysis';

type Page = {
  /** Is page full or empty */
  isFull: boolean;
  tour: {
    version: number;
    currentVersion: number;
  };
};

export type SiteSettings = {
  theme: Record<string, string>;
  title: string;
  faviconUrl: string;
  logoUrl: string;
  logoUrlContrast: string;
  logoWidth?: string;
  disableCoachingIntro: boolean;
};

export interface UiInterface {
  dashboardView: 'homeView' | 'dataView';
  theme: UiThemeEnum;
  prompts: {
    /**
     * Whether to prompt the user to connect their financial accounts
     */
    connectAccounts: boolean,
  };
  /**
   * Whether the mobile drawer is open
   */
  mobileMenuOpen: boolean;
  wealthTab: WealthTabsEnum;
  /**
   * Version for new Features dialog
   */
  features: number;
  isDialogOpen: boolean;
  pageName: string;
  hasLoadedSettings: boolean;
  pages?: Record<TourPages, Page>;
  settings?: {
    version: number;
    isLoaded: boolean;
    site: SiteSettings;
  };
}

const initialState: UiInterface = {
  settings: {
    version: 1,
    isLoaded: false,
    site: {
      title: null,
      faviconUrl: null,
      logoUrl: null,
      logoUrlContrast: null,
      theme: {},
      disableCoachingIntro: false
    }
  },
  hasLoadedSettings: false,
  mobileMenuOpen: false,
  dashboardView: 'homeView',
  theme: UiThemeEnum.LIGHT,
  prompts: {
    connectAccounts: true,
  },
  wealthTab: WealthTabsEnum.OVERVIEW,
  features: 2,
  isDialogOpen: false,
  pageName: null,
  pages: {
    dashboard: {
      isFull: false,
      tour: {
        version: 0,
        currentVersion: 1,
      }
    },
    investments: {
      isFull: false,
      tour: {
        version: 0,
        currentVersion: 1,
      }
    },
    investmentsAnalysis: {
      isFull: false,
      tour: {
        version: 0,
        currentVersion: 1,
      }
    }
  }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsDialogOpen: (state, action) => {
      state.isDialogOpen = action.payload;
    },
    setDashboardView: (state, action) => {
      state.dashboardView = action.payload;
    },
    switchTheme: (state, action) => {
      state.theme = action.payload;
    },
    setPrompts: (state, action) => {
      state.prompts = {
        ...state.prompts,
        ...action.payload,
      };
    },
    setWealthTab: (state, action) => {
      state.wealthTab = action.payload;
    },
    setPageName: (state, action) => {
      state.pageName = action.payload;
    },
    setPageTour: (state, { payload }: PayloadAction<{ name: string; version: number;}>) => {
      state.pages[payload.name].tour.version = payload.version;
    },
    setPageFull: (state, { payload }: PayloadAction<string>) => {
      state.pages[payload].isFull = true;
    },
    updateThemeSettings: (state, { payload }: PayloadAction<SiteSettings | null>) => {
      state.settings = {
        site: {
          ...state.settings.site,
          ...(payload ? payload : {}),
        },
        isLoaded: true,
        version: SETTINGS_VER
      };
      saveThemeOverride(overrideThemeUtil(payload ? payload.theme : {}));
      saveState({ ui: state });
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(settingsApi.endpoints.settings.matchPending, (state) => {
        state.hasLoadedSettings = false;
      })
      .addMatcher(settingsApi.endpoints.settings.matchFulfilled, (state, { payload }) => {
        Object.keys(payload.pages).forEach(p => {
          state.pages[p].tour.version = payload.pages[p].tour?.version ?? 1;
        });
        state.hasLoadedSettings = true;
      });
  },
});

export default uiSlice.reducer;
export const {
  setDashboardView,
  switchTheme,
  setPrompts,
  setIsDialogOpen,
  setPageName,
  setWealthTab,
  setPageFull,
  updateThemeSettings,
  setPageTour
} = uiSlice.actions;

const selectUi = (state: StoreInterface) => state.ui;

export const uiSelector = createSelector((state: StoreInterface) => state, values => values.ui);
export const uiPromptsSelector = createSelector(selectUi, v => v.prompts);
export const uiSettingsSelector = createSelector(selectUi, v => v.settings);
