import { IconProps } from 'common/otto-ui/icons/icon.type';

export const LogoExperts = ({ width = 24, height = 24 }:IconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8181 10.8182C15.4289 10.8182 17.5453 8.70176 17.5453 6.09099C17.5453 3.48022 15.4289 1.36377 12.8181 1.36377C10.2073 1.36377 8.09088 3.48022 8.09088 6.09099C8.09088 8.70176 10.2073 10.8182 12.8181 10.8182Z"
      stroke="currentColor"
      strokeWidth="1.1818"
      strokeMiterlimit="10"
    />
    <path
      d="M5.65247 14.9535C6.37895 13.696 7.42356 12.6519 8.68134 11.9259C9.93913 11.2 11.3658 10.8179 12.8181 10.8179C14.2703 10.8179 15.697 11.2001 16.9547 11.926C18.2125 12.652 19.2571 13.6962 19.9836 14.9536"
      stroke="currentColor"
      strokeWidth="1.1818"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8069 20.8729L23.2397 21.7807C23.4228 21.8967 23.6502 21.7241 23.5959 21.5103L23.1819 19.8819C23.1702 19.8365 23.1716 19.7888 23.1859 19.7442C23.2002 19.6996 23.2267 19.6599 23.2625 19.6297L24.5474 18.5603C24.7162 18.4198 24.6291 18.1396 24.4122 18.1255L22.7342 18.0166C22.689 18.0134 22.6457 17.9974 22.6092 17.9705C22.5728 17.9436 22.5447 17.9069 22.5283 17.8646L21.9025 16.2887C21.8855 16.2439 21.8553 16.2053 21.8158 16.1781C21.7763 16.1508 21.7295 16.1362 21.6816 16.1362C21.6336 16.1362 21.5868 16.1508 21.5473 16.1781C21.5079 16.2053 21.4776 16.2439 21.4606 16.2887L20.8348 17.8646C20.8184 17.9069 20.7904 17.9436 20.7539 17.9705C20.7174 17.9974 20.6741 18.0134 20.6289 18.0166L18.951 18.1255C18.7341 18.1396 18.6469 18.4198 18.8158 18.5603L20.1006 19.6297C20.1364 19.6599 20.163 19.6996 20.1772 19.7442C20.1915 19.7888 20.1929 19.8365 20.1812 19.8819L19.7973 21.3921C19.7321 21.6486 20.005 21.8557 20.2247 21.7165L21.5562 20.8729C21.5937 20.8491 21.6372 20.8364 21.6816 20.8364C21.726 20.8364 21.7694 20.8491 21.8069 20.8729V20.8729Z"
      stroke="currentColor"
      strokeWidth="0.827263"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.07982 20.8729L5.51263 21.7807C5.69578 21.8967 5.92317 21.7241 5.86883 21.5103L5.45486 19.8819C5.4432 19.8365 5.44458 19.7888 5.45884 19.7442C5.4731 19.6996 5.49966 19.6599 5.53547 19.6297L6.82032 18.5603C6.98914 18.4198 6.90201 18.1396 6.6851 18.1255L5.00717 18.0166C4.96198 18.0134 4.91863 17.9974 4.88218 17.9705C4.84572 17.9436 4.81766 17.9069 4.80127 17.8646L4.17547 16.2887C4.15846 16.2439 4.12821 16.2053 4.08875 16.1781C4.04928 16.1508 4.00246 16.1362 3.95451 16.1362C3.90656 16.1362 3.85974 16.1508 3.82028 16.1781C3.78081 16.2053 3.75056 16.2439 3.73355 16.2887L3.10776 17.8646C3.09136 17.9069 3.0633 17.9436 3.02685 17.9705C2.99039 17.9974 2.94705 18.0134 2.90185 18.0166L1.22392 18.1255C1.00702 18.1396 0.919879 18.4198 1.0887 18.5603L2.37355 19.6297C2.40936 19.6599 2.43592 19.6996 2.45018 19.7442C2.46444 19.7888 2.46582 19.8365 2.45417 19.8819L2.07026 21.3921C2.00504 21.6486 2.27791 21.8557 2.49769 21.7165L3.8292 20.8729C3.86665 20.8491 3.91012 20.8364 3.95451 20.8364C3.9989 20.8364 4.04238 20.8491 4.07982 20.8729V20.8729Z"
      stroke="currentColor"
      strokeWidth="0.827263"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9433 22.055L14.3762 22.9628C14.5593 23.0788 14.7867 22.9063 14.7324 22.6925L14.3184 21.064C14.3067 21.0186 14.3081 20.9709 14.3224 20.9263C14.3366 20.8817 14.3632 20.842 14.399 20.8119L15.6838 19.7424C15.8527 19.6019 15.7655 19.3217 15.5486 19.3077L13.8707 19.1988C13.8255 19.1955 13.7822 19.1795 13.7457 19.1526C13.7092 19.1257 13.6812 19.089 13.6648 19.0468L13.039 17.4709C13.022 17.426 12.9917 17.3874 12.9523 17.3602C12.9128 17.3329 12.866 17.3184 12.818 17.3184C12.7701 17.3184 12.7233 17.3329 12.6838 17.3602C12.6443 17.3874 12.6141 17.426 12.5971 17.4709L11.9713 19.0468C11.9549 19.089 11.9268 19.1257 11.8904 19.1526C11.8539 19.1795 11.8106 19.1955 11.7654 19.1988L10.0874 19.3077C9.87054 19.3217 9.7834 19.6019 9.95223 19.7424L11.2371 20.8119C11.2729 20.842 11.2994 20.8817 11.3137 20.9263C11.328 20.9709 11.3293 21.0186 11.3177 21.064L10.9338 22.5742C10.8686 22.8308 11.1414 23.0379 11.3612 22.8986L12.6927 22.055C12.7302 22.0312 12.7736 22.0185 12.818 22.0185C12.8624 22.0185 12.9059 22.0312 12.9433 22.055V22.055Z"
      stroke="currentColor"
      strokeWidth="0.827263"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
