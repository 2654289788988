import { CreateInstantRoomDocument, CreateSuitabilityReportDocument, GenerateSuitabilityReportDocument, GetReportsDocument, ListSuitabilityReportsDocument } from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';
import { SuitabilityReportInterface } from 'common/interfaces/suitability-report.interface';

interface CreateReportArgs {
  advisorEmail: string;
  advisorFirstName: string;
  advisorLastName: string;
  customerEmail: string;
  customerFirstName: string;
  customerLastName: string;
}

export const annualReportApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createReport: builder.mutation<SuitabilityReportInterface, CreateReportArgs>({
      query: (variables) => ({
        variables: { input: variables },
        document: CreateSuitabilityReportDocument
      }),
      invalidatesTags: [API_TAGS.REPORTS],
      transformResponse: (data: { createSuitabilityReport; }) => data.createSuitabilityReport
    }),
    report: builder.query<SuitabilityReportInterface, { id: string; token: string; }>({
      query: (variables) => ({
        variables,
        document: GetReportsDocument
      }),
      providesTags: (_, __, arg) => [{ type: API_TAGS.REPORTS, id: arg.id }],
      transformResponse: (data: { fetchSuitabilityReport; }) => data.fetchSuitabilityReport,
    }),
    listReports: builder.query<SuitabilityReportInterface[], { ids: string[]; tokens: string[]; }>({
      query: (variables) => ({
        variables,
        document: ListSuitabilityReportsDocument
      }),
      providesTags: [API_TAGS.REPORTS],
      transformResponse: (data: { listSuitabilityReports; }) => data.listSuitabilityReports ? data.listSuitabilityReports.toReversed() : [],
    }),
    createInstantRoom: builder.mutation<{ token: string; recordingUrl: string; }, { id: string; role: string; }>({
      query: (variables) => ({
        variables,
        document: CreateInstantRoomDocument
      }),
      transformResponse: (data: { createInstantRoom; }) => data.createInstantRoom
    }),
    generateReport: builder.mutation<{ token: string; id: string; }, { token: string; id: string; }>({
      query: (variables) => ({
        variables,
        document: GenerateSuitabilityReportDocument
      }),
      invalidatesTags: [API_TAGS.REPORTS],
    }),
  }),
});

export const {
  useListReportsQuery,
  useCreateReportMutation,
  useCreateInstantRoomMutation,
  useReportQuery,
  useLazyReportQuery,
  useGenerateReportMutation
} = annualReportApi;
