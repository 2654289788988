import { useRipple } from 'common/roq-hooks/use-ripple/use-ripple.hook';
import { MouseEventHandler } from 'react';

export const NextArrow = (props: { onClick?: () => void }) => {
  const { onClick = () => undefined } = props;
  const { addRipple, ripples } = useRipple();
  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    addRipple(event);
  };

  return (
    <button onClick={onClick} onMouseDown={handleMouseDown}>
      <svg
        className="w-24 h-24 sm:w-16 sm:h-16 md:w-20 md:h-20"
        viewBox="0 0 104 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="52" cy="52" r="50.5" stroke="#F3F3F3" strokeWidth="3" />
        <circle cx="52" cy="52" r="42" fill="url(#paint0_linear)" />
        <path
          d="M38 51H66L52 65M52 37L60 45"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="10"
            y1="52.0002"
            x2="90.304"
            y2="52.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#53DADA" />
            <stop offset="1" stopColor="#61D57F" />
          </linearGradient>
        </defs>
        <foreignObject className="node" x="0" y="0" width="100%" height="100%" style={{ borderRadius: 999 }}>
          {ripples}
        </foreignObject>
      </svg>
    </button>
  );
};
