export const LogoCalendar = () => (
  <svg width="24" height="24" viewBox="0 0 26 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5736 2.39383H21.2804V0.82451C21.2804 0.36916 20.9579 0 20.5601 0C20.1623 0 19.8398 0.36916 19.8398 0.82451V2.39383H6.16114V0.82451C6.16114 0.36916 5.83863 0 5.44082 0C5.04301 0 4.7205 0.36916 4.7205 0.82451V2.39383H1.42735C1.02954 2.39383 0.707031 2.76299 0.707031 3.21834V8.51608V23.7624C0.707031 24.2177 1.02954 24.5869 1.42735 24.5869H24.5736C24.9714 24.5869 25.2939 24.2177 25.2939 23.7624V8.51608V3.21834C25.2939 2.76299 24.9714 2.39383 24.5736 2.39383ZM2.14767 4.04285H4.7205V5.2181C4.7205 5.67345 5.04301 6.04261 5.44082 6.04261C5.83863 6.04261 6.16114 5.67345 6.16114 5.2181V4.04285H19.8398V5.2181C19.8398 5.67345 20.1623 6.04261 20.5601 6.04261C20.9579 6.04261 21.2804 5.67345 21.2804 5.2181V4.04285H23.8533V7.69157H2.14767V4.04285ZM23.8533 22.9379H2.14767V9.34059H23.8533V22.9379Z"
      fill="currentColor"
    />
    <path
      d="M5.59922 13.1161H8.13182C8.52962 13.1161 8.85214 12.7469 8.85214 12.2916C8.85214 11.8362 8.52962 11.467 8.13182 11.467H5.59922C5.20142 11.467 4.87891 11.8362 4.87891 12.2916C4.87891 12.7469 5.20137 13.1161 5.59922 13.1161Z"
      fill="currentColor"
    />
    <path
      d="M11.734 13.1161H14.2666C14.6644 13.1161 14.9869 12.7469 14.9869 12.2916C14.9869 11.8362 14.6644 11.467 14.2666 11.467H11.734C11.3362 11.467 11.0137 11.8362 11.0137 12.2916C11.0137 12.7469 11.3362 13.1161 11.734 13.1161Z"
      fill="currentColor"
    />
    <path
      d="M17.8688 13.1161H20.4013C20.7992 13.1161 21.1217 12.7469 21.1217 12.2916C21.1217 11.8362 20.7992 11.467 20.4013 11.467H17.8688C17.4709 11.467 17.1484 11.8362 17.1484 12.2916C17.1484 12.7469 17.4709 13.1161 17.8688 13.1161Z"
      fill="currentColor"
    />
    <path
      d="M5.59922 16.9637H8.13182C8.52962 16.9637 8.85214 16.5946 8.85214 16.1392C8.85214 15.6839 8.52962 15.3147 8.13182 15.3147H5.59922C5.20142 15.3147 4.87891 15.6839 4.87891 16.1392C4.87891 16.5946 5.20137 16.9637 5.59922 16.9637Z"
      fill="currentColor"
    />
    <path
      d="M11.734 16.9637H14.2666C14.6644 16.9637 14.9869 16.5946 14.9869 16.1392C14.9869 15.6839 14.6644 15.3147 14.2666 15.3147H11.734C11.3362 15.3147 11.0137 15.6839 11.0137 16.1392C11.0137 16.5946 11.3362 16.9637 11.734 16.9637Z"
      fill="currentColor"
    />
    <path
      d="M17.8688 16.9637H20.4013C20.7992 16.9637 21.1217 16.5946 21.1217 16.1392C21.1217 15.6839 20.7992 15.3147 20.4013 15.3147H17.8688C17.4709 15.3147 17.1484 15.6839 17.1484 16.1392C17.1484 16.5946 17.4709 16.9637 17.8688 16.9637Z"
      fill="currentColor"
    />
    <path
      d="M5.59922 20.8114H8.13182C8.52962 20.8114 8.85214 20.4422 8.85214 19.9869C8.85214 19.5315 8.52962 19.1624 8.13182 19.1624H5.59922C5.20142 19.1624 4.87891 19.5315 4.87891 19.9869C4.87891 20.4422 5.20137 20.8114 5.59922 20.8114Z"
      fill="currentColor"
    />
    <path
      d="M11.734 20.8114H14.2666C14.6644 20.8114 14.9869 20.4422 14.9869 19.9869C14.9869 19.5315 14.6644 19.1624 14.2666 19.1624H11.734C11.3362 19.1624 11.0137 19.5315 11.0137 19.9869C11.0137 20.4422 11.3362 20.8114 11.734 20.8114Z"
      fill="currentColor"
    />
    <path
      d="M17.8688 20.8114H20.4013C20.7992 20.8114 21.1217 20.4422 21.1217 19.9869C21.1217 19.5315 20.7992 19.1624 20.4013 19.1624H17.8688C17.4709 19.1624 17.1484 19.5315 17.1484 19.9869C17.1484 20.4422 17.4709 20.8114 17.8688 20.8114Z"
      fill="currentColor"
    />

    <defs>
      <clipPath id="clip0">
        <rect width="24.5869" height="24.5869" fill="white" transform="translate(0.707031)" />
      </clipPath>
    </defs>
  </svg>
);
