import { useSelector } from "react-redux";
import { currentSessionSelector } from "slices/session";
import dynamic from "next/dynamic";

const SessionRoomView = dynamic(() => import('components/session-meeting/session-room-view').then((c) => c.SessionRoomView), { 
  ssr: false,
});

export const SessionRoom = () => {
  const { isActive, id } = useSelector(currentSessionSelector);
  if (!isActive || !id) {
    return null;
  }
  return <SessionRoomView />;
};
