export const PlusSquare = () => (
    <svg cursor="pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="url(#paint0_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 8V16" stroke="url(#paint1_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 12H16" stroke="url(#paint2_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear" x1="3" y1="12" x2="20.208" y2="12" gradientUnits="userSpaceOnUse">
                <stop stopColor="#53DADA"/>
                <stop offset="1" stopColor="#61D57F"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="12" y1="12" x2="12.956" y2="12" gradientUnits="userSpaceOnUse">
                <stop stopColor="#53DADA"/>
                <stop offset="1" stopColor="#61D57F"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="8" y1="12.5" x2="15.648" y2="12.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#53DADA"/>
                <stop offset="1" stopColor="#61D57F"/>
            </linearGradient>
        </defs>
    </svg>
)
