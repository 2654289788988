export enum ROUTE_ENUM {
  HOME = '/',
  USERS = '/users',
  USERS_PROFILE = '/users/[userId]',
  COACH = '/adviser',
  ACCOUNT_ACTIVATE = '/account-activate',
  INVALID_EMAIL = '/invalid-email',
  RESTORE_PASS = '/restore-password',
  FORGOT_PASS = '/forgot-password',
  SUB_EXPIRED = '/subscription-expired',
  LOGIN = '/login',
  REGISTER = '/register',
  SETTINGS = '/settings',
  WELCOME = '/welcome',
  ROADMAP = '/roadmap',
  CONNECT_CALENDAR = '/connect-calendar',
  ONBOARDING_MESSAGE = '/onboarding-message',
  BOOK_SESSION = '/book-session',
  NOT_FOUND = '/not-found',
  EVENTS = '/events',
  EVENT = '/events/[event]',
  SETTINGS_PROFILE = '/settings/profile',
  SETTINGS_ACCOUNT = '/settings/account',
  ACTIONS = '/actions',
  MESSAGES = '/messages',
  COACH_PROFILE = '/coach-profile',
  SESSION_LIST =  '/session-list',
  SESSION_DETAILS =  '/session-details',
  CALENDAR = '/calendar',
  EXPERTS = '/experts',
  COACHES = '/coaches',
  ADMINS = '/admins',
  AVAILABILITY = '/availability',
  LEARN = '/learn',
  LEARN_ARTICLE = '/learn/article/[id]',
  LEARN_TOPIC = '/learn/topic/[id]',
  OTTOMATE = '/rules',
  WEALTH = '/wealth',
  WEALTH_CASHFLOW = '/wealth/cashflow',
  WEALTH_INVESTMENTS = '/wealth/investments',
  WEALTH_ASSETS = '/wealth/assets',
  WEALTH_ADD_ASSET = '/wealth/assets/add',
  WEALTH_INVESTMENT_ANALYSIS = '/wealth/investment-analysis',
  WEALTH_INVESTMENT_IDEAS = '/wealth/investment-ideas',
  TRANSACTIONS = '/wealth/transactions',
  AUTH_REDIRECTION = '/auth-redirection',
  DOCUMENTS = '/documents',
  DOCUMENTS_SHARED = '/documents/shared',
  DOCUMENTS_SEARCH = '/documents/search',
  ANNUAL_REVIEW = '/annual-review',
  ANNUAL_REVIEW_MEETING = '/annual-review/meeting'
}

export interface RouteInterface {
  // Route name
  route: ROUTE_ENUM;
}

export const routes: RouteInterface[] = [
  {
    route: ROUTE_ENUM.HOME,
  },
  {
    route: ROUTE_ENUM.USERS,
  },
  {
    route: ROUTE_ENUM.ACCOUNT_ACTIVATE,
  },
  {
    route: ROUTE_ENUM.INVALID_EMAIL,
  },
  {
    route: ROUTE_ENUM.RESTORE_PASS,
  },
  {
    route: ROUTE_ENUM.FORGOT_PASS,
  },
  {
    route: ROUTE_ENUM.SUB_EXPIRED,
  },
  {
    route: ROUTE_ENUM.LOGIN,
  },
  {
    route: ROUTE_ENUM.REGISTER,
  },
  {
    route: ROUTE_ENUM.SETTINGS,
  },
  {
    route: ROUTE_ENUM.WELCOME,
  },
  {
    route: ROUTE_ENUM.ROADMAP,
  },
  // {
  //   route: ROUTE_ENUM.WEALTH,
  // },
];
