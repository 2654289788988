import { FormDatePickerField } from "components/form";
import { FormTextField } from "components/form/form-text-field-v2";

export const HoldingFormFields = () => (
  <>
    <FormTextField name="amount" type="number" step="any" label="Amount" min={0} required />
    <div>
      <FormDatePickerField name="date" label="Date" maxDate={new Date()} clearable />
    </div>
  </>
);
