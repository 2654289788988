const ROTATION_INCREMENT = 30;

export const Loader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto' }}
    viewBox="0 0 100 100"
  >
    {[...Array(12).keys()].map((index) => (
      <g key={index} transform={`rotate(${index * ROTATION_INCREMENT} 50 50)`}>
        <rect x="47" y="24" rx="3" ry="3.6" width="6" height="12" fill="#53dada">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1.3157894736842106s"
            begin={`-${1.2061403508771928 - index * 0.109649123}s`}
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
    ))}
  </svg>
);
