import { gql } from 'graphql-request';

const commonCoachFields = `
  id
  firstName
  lastName
  email
  avatar
  avatarImageIdentifier
`;

export const UpdateCoachDocument = gql`
  mutation UpdateCoach($id: String!, $user: UserUpdateDto!) {
    updateUser(id: $id, user: $user) {
      ${commonCoachFields}
    }
  }
`;

export const CreateCoachDocument = gql`
  mutation CreateCoach($input: InvitationInviteDto!){
    createAndInviteUser(input: $input){
      ${commonCoachFields}
    }
  }
`;

export const CreateCoachMetaInfoDocument = gql`
  mutation CreateCoachMetaInfo($coachMetaInfoCreateDto: CoachMetaInfoCreateDto!) {
    createCoachMetaInfo(coachMetaInfo: $coachMetaInfoCreateDto) {
      id
    }
  }
`;

export const UpdateCoachMetaInfoDocument = gql`
  mutation UpdateCoachMetaInfo($id: String!, $coachMetaInfoCreateDto: CoachMetaInfoUpdateDto!) {
    updateCoachMetaInfo(id: $id, coachMetaInfo: $coachMetaInfoCreateDto) {
      id
    }
  }
`;

export const CoachRelateExpertisesDocument = gql`
  mutation CoachRelateExpertises(
    $id: String!
    $coachMetaInfoCoachExpertiseRelationDto: CoachMetaInfoCoachExpertiseRelationDto!
  ) {
    relateCoachExpertisesToCoachMetaInfo(id: $id, relation: $coachMetaInfoCoachExpertiseRelationDto)
  }
`;

export const CoachUnrelateExpertisesDocument = gql`
  mutation CoachUnrelateExpertises(
    $id: String!
    $coachMetaInfoCoachExpertiseRelationDto: CoachMetaInfoCoachExpertiseRelationDto!
  ) {
    unrelateCoachExpertisesFromCoachMetaInfo(id: $id, relation: $coachMetaInfoCoachExpertiseRelationDto)
  }
`;

export const CoachExpertisesDocument = gql`
  query CoachExpertises {
    coachExpertises {
      data {
        id
        name
        icon
      }
    }
  }
`;
