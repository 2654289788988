import { TextField, Typography } from "common/otto-ui";
import { TextFieldInterface } from "common/otto-ui/text-field";
import { FormFieldProps } from "components/form/types";
import { useField } from 'formik';
import React from "react";
import { capitalizeFirstLetter } from "utils";

export const FormTextField = ({ label, type, description, ...props }: FormFieldProps & TextFieldInterface) => {
  const [field, meta] = useField(props);
  return (
    <div className="grid gap-2">
      <label htmlFor={field.name}><Typography semiBold className="capitalize">{label}</Typography></label>
      {description && <Typography variant="body-2">{description}</Typography>}
      <TextField
        id={field.name}
        {...props}
        outline
        name={field.name}
        value={field.value}
        type={type}
        helperText={meta.touched && capitalizeFirstLetter(meta.error)}
        status={meta.touched && meta.error ? 'error' : 'default'}
        placeholder={props.placeholder || ''}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
    </div>
  );
};
